<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <table class="table table-bordered" style="width: 100%;margin: 0px 12px;margin-bottom: 20px;">
              <tr>
                <th>{{ $t('org_pro.organization') }}</th>
                <td>{{ ($i18n.locale==='bn') ? pumpOperator.org_name_bn :  pumpOperator.org_name }}</td>
                <th>{{ $t('irriPumpInformation.pump_id') }}</th>
                <td>{{ ($i18n.locale==='bn') ? pumpOperator.pump_id :  pumpOperator.pump_id }}</td>
              </tr>
              <tr>
                <th>{{ $t('user.namel') }}</th>
                <td>{{ ($i18n.locale==='bn') ? pumpOperator.name_bn :  pumpOperator.name }}</td>
                <th>{{ $t('externalUserIrrigation.nid') }}</th>
                <td>{{ pumpOperator.nid | mobileNumber}}</td>
              </tr>
              <tr>
                <th>{{ $t('externalUserIrrigation.father_name') }}</th>
                <td>{{ ($i18n.locale==='bn') ? pumpOperator.father_name_bn : pumpOperator.father_name }}</td>
                <th>{{ $t('externalUserIrrigation.mother_name') }}</th>
                <td>{{ ($i18n.locale==='bn') ? pumpOperator.mother_name_bn : pumpOperator.mother_name }}</td>
              </tr>
              <tr>
                <th>{{ $t('pusti_mapping.gender') }}</th>
                <td>{{ (pumpOperator.gender === 1) ? $t('pusti_mapping.male') : $t('pusti_mapping.female') }}</td>
                <slot  v-if="pumpOperator.gender === 2">
                  <th>{{ $t('irriPumpInformation.husband_name') }}</th>
                  <td>{{ pumpOperator.husband_name }}</td>
                </slot>
              </tr>
              <tr>
                <th>{{ $t('externalUserIrrigation.village') }}</th>
                <td>{{ ($i18n.locale==='bn') ? pumpOperator.village_name_bn : pumpOperator.village_name }}</td>
                <th>{{ $t('user.phone_no') }}</th>
                <td>{{ pumpOperator.mobile_no | mobileNumber }}</td>
              </tr>
              <tr>
                <th>{{ $t('irriPumpInformation.email') }}</th>
                <td>{{ pumpOperator.email }}</td>
              </tr>
              <tr>
                <th>{{ $t('irriPumpInformation.latitude') }}</th>
                <td>{{ pumpOperator.latitude }}</td>
                <th>{{ $t('irriPumpInformation.longitude') }}</th>
                <td>{{ pumpOperator.longitude }}</td>
              </tr>
            </table>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: ['item'],
  components: {
  },
  data () {
    return {
      pumpOperator: {},
      testId: 0,
      rows: [],
      genderList: [
        {
          gender_value: 1,
          gender_name: 'Male',
          gender_name_bn: 'পুরুষ'
        },
        {
          gender_value: 2,
          gender_name: 'Female',
          gender_name_bn: 'মহিলা'
        }
      ]
    }
  },
  computed: {
    pumpOperatorData () {
      const objectData = this.genderList.find(item => item.gender_value === this.pumpOperator.gender)
      return Object.assign({}, this.pumpOperator, objectData)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  created () {
    this.pumpOperator = this.item
  }
}
</script>
