<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriPumpInformation.pump_operator') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.organization')"
              label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationtList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irriPumpInformation.pump_id')"
              label-for="pump_id"
            >
              <b-form-select
                plain
                v-model="search.pump_id"
                :options="pumpList"
                id="pump_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('user.namel')"
                    label-for="name"
                    >
                    <b-form-input
                        id="name"
                        v-model="search.name"
                        placeholder=""
                        ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('user.phone_no')"
                    label-for="mobile_no"
                    >
                    <b-form-input
                        id="mobile_no"
                        v-model="search.mobile_no"
                        placeholder=""
                        ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('externalUserIrrigation.nid')"
                    label-for="nid"
                    >
                    <b-form-input
                        id="nid"
                        type="number"
                        v-model="search.nid"
                        placeholder=""
                        ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.pump_operator_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ data.item.org_name }}</span>
                    </template>
                    <template v-slot:cell(mobile_no)="data">
                      <span class="capitalize">{{ data.item.mobile_no | mobileNumber }}</span>
                    </template>
                    <template v-slot:cell(nid)="data">
                      <span class="capitalize">{{ data.item.nid }}</span>
                    </template>
                    <!-- <template v-slot:cell(status)="data">
                      <span class="badge badge-danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</span>
                      <span class="badge badge-success" v-else>{{ $t('globalTrans.active') }}</span>
                    </template> -->
                    <!-- <template v-slot:cell(created_at)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat }}</span>
                    </template> -->
                    <template v-slot:cell(action)="data">
                      <!-- <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                      <!-- <b-button title="Active/Inactive" variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                      <!-- <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                      <!-- <a href="javascript:" class="btn_table_action table_action_view" title="View Complain" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a> -->
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <!-- <a href="javascript:" class="btn_table_action table_action_status" title="Active" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Inactive" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a> -->
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

    <b-modal id="modal-5" size="lg" :title="$t('irriPumpInformation.pump_operator_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
       <Details :item="item" :key="testId" ref="details" />
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './FormV'
import Details from './Details'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOperatorToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        FormV, Details
    },
    data () {
        return {
          irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
            search: {
                pump_id: 0,
                org_id: 0,
                name: '',
                mobile_no: '',
                nid: ''
            },
            pumpList: [],
            item: '',
            testId: 0,
            rows: []
        }
    },
    computed: {
        ...mapGetters({
        authUser: 'Auth/authUser'
       }),
        organizationtList: function () {
            return this.$store.state.commonObj.organizationProfileList
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('irriPumpInformation.pump_operator_entry') : this.$t('irriPumpInformation.pump_operator_modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.organization'), class: 'text-center' },
                { label: this.$t('irriPumpInformation.pump_id'), class: 'text-center' },
                { label: this.$t('user.namel'), class: 'text-center' },
                { label: this.$t('externalUserIrrigation.nid'), class: 'text-center' },
                { label: this.$t('user.phone_no'), class: 'text-center' },
                // { label: this.$t('pump_install.created_date'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'org_name_bn' },
                    { key: 'pump_id' },
                    { key: 'name_bn' },
                    { key: 'nid' },
                    { key: 'mobile_no' },
                    // { key: 'created_at' },
                    { key: 'remarks' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'org_name' },
                    { key: 'pump_id' },
                    { key: 'name' },
                    { key: 'nid' },
                    { key: 'mobile_no' },
                    // { key: 'created_at' },
                    { key: 'remarks' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
              return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
      'search.org_id': function (newVal, oldVal) {
        this.getPumpId(newVal)
      }
    },
    async created () {
        // this.getPumpId()
    },
    mounted () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    methods: {
      searchData () {
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
          this.search = Object.assign({}, this.search, {
            org_id: this.authUser.office_detail.org_id
          })
        } else if (this.authUser.role_id === 0) {
          this.search = Object.assign({}, this.search, {
            org_id: this.authUser.office_detail.org_id
          })
        }
        this.loadData()
      },
      details (item) {
        this.item = item
      },
      remove (item) {
        this.changeStatus(irriSchemeServiceBaseUrl, pumpOperatorToggleStatus, item)
      },
      async loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        await RestApi.getData(irriSchemeServiceBaseUrl, 'pump-operator/importer-data-list', params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.dataList(response.data.data))
            this.paginationData(response.data)
          } else {
                this.$store.dispatch('setList', [])
            }
        })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      dataList (data) {
        const orgList = this.$store.state.orgList
        let tmpData = {}
        const listData = data.map(item => {
          tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
          const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
          return Object.assign({}, item, orgData)
        })
        return listData
      },
      getPumpId (orgId) {
        RestApi.getData(irriSchemeServiceBaseUrl, 'pump-operator/list-byPump_id', { org_id: orgId }).then(response => {
          if (response.success) {
            this.pumpList = response.data.map((obj, index) => {
              if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.pump_id }
              } else {
                return { value: obj.id, text: obj.pump_id }
              }
            })
          }
        })
      }
    }
}
</script>
