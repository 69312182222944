<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Pump ID" vid='pump_id' rules="required">
                                    <b-form-group
                                        class="row"
                                        :label-cols-sm="id ? '5' : '4'"
                                        label-for="pump_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('irriPumpInformation.pump_id')}} <span class="text-danger">*</span>
                                    </template>
                                        <b-form-input
                                        id="pump_id"
                                        v-model="pumpOperator.pump_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="NID" vid='nid' :rules="'required|max:'+(invalidNid ? 0 : (10|13|17))+'|min:10|numeric'">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="nid"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('externalUserIrrigation.nid')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="number"
                                        id="nid"
                                        v-model="pumpOperator.nid"
                                        @input="nidRules($event)"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Mobile" vid='mobile_no' rules="required|min:11|max:11">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        :label="$t('user.phone_no')"
                                        label-for="mobile_no"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('irriPumpInformation.mobile')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="number"
                                            id="mobile_no"
                                            v-model="pumpOperator.mobile_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                        </div>
                    </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { userStore, userDelete, pumpOperatorStore, pumpOperatorImporterUpdate } from '../../api/routes'

export default {
  name: 'PumpOperatorForm',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getPumpOperatorData()
      this.invalidNid = (parseInt(tmp.nid.toString().length) === 10 || parseInt(tmp.nid.toString().length) === 13 || parseInt(tmp.nid.toString().length) === 17) ? 0 : 1
      this.pumpOperator = tmp
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-information/list-allId', { org_id: this.pumpOperator.org_id }).then(response => {
        if (response.success) {
            const activeId = response.data.filter(item => item.status === 0)
            const pumpData = activeId.filter(pump => pump.id === this.pumpOperator.pump_id)
            this.pumpListEdit = pumpData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.pump_id }
                } else {
                return { value: obj.id, text: obj.pump_id }
                }
            })
        }
      })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      isEdit: false,
      genders: [
        { item: 1, name: this.$t('pusti_mapping.male') },
        { item: 2, name: this.$t('pusti_mapping.female') }
      ],
      pumpOperator: {
        org_id: 0,
        pump_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        mouza_id: 0,
        name: '',
        name_bn: '',
        father_name: '',
        father_name_bn: '',
        mother_name: '',
        mother_name_bn: '',
        nid: '',
        village_name: '',
        village_name_bn: '',
        mobile_no: '',
        email: '',
        latitude: '',
        longitude: '',
        pump_operator_user_id: 0,
        pump_operator_username: '',
        pump_operator_email: '',
        daily_task_entry_required: 0,
        invalidNid: 1
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      pumpListEdit: [],
      mouzaList: [],
      pumpList: []
    }
  },
  computed: {
    getOrgList () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'pumpOperator.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'pumpOperator.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'pumpOperator.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'pumpOperator.union_id': function (newVal, oldVal) {
        if (!this.id) {
            this.mouzaList = this.getMouzaList(newVal)
            this.pumpList = this.getPumpId(newVal)
        }
    },
    'pumpOperator.mouza_id': function (newVal, oldVal) {
        if (!this.id) {
            this.pumpList = this.getPumpId(this.pumpOperator.union_id, newVal)
        }
    }
  },
  methods: {
    nidRules (e) {
        if (e.length) {
            this.invalidNid = (parseInt(e.length) === 10 || parseInt(e.length) === 13 || parseInt(e.length) === 17) ? 0 : 1
        } else {
            this.invalidNid = 1
        }
    },
    getPumpOperatorData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      this.isEdit = true
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      try {
        let result = null
        let userResult = null
        const loadinState = { loading: false, listReload: false }

        if (this.pumpOperator.id) {
            result = await RestApi.putData(irriSchemeServiceBaseUrl, `${pumpOperatorImporterUpdate}/${this.id}`, this.pumpOperator)
        } else {
            userResult = await RestApi.postData(authServiceBaseUrl, userStore, this.pumpOperator)
            if (!userResult.success) {
                this.$refs.form.setErrors(userResult.errors)
            }
            this.pumpOperator.pump_operator_user_id = userResult.data.id
            this.pumpOperator.pump_operator_username = userResult.data.username
            this.pumpOperator.pump_operator_email = userResult.data.email

            if (userResult) {
                result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOperatorStore, this.pumpOperator)

                if (!result.success) {
                    RestApi.deleteData(authServiceBaseUrl, `${userDelete}/${userResult.data.id}`)
                }
            }
        }

        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
            this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })

            this.$bvModal.hide('modal-4')
        } else {
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
            })
        }
      } catch (error) {
         this.$refs.form.setErrors(error)
         this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
            })
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getMouzaList (unionId = null) {
      const mouzaList = this.$store.state.IrriConfig.commonObj.mouzaList.filter(item => item.status === 0)
      if (unionId) {
        return mouzaList.filter(union => union.union_id === unionId)
      }
      return mouzaList
    },
    getPumpId (pumpId, mouzaId = 0) {
        const params = {
            org_id: this.pumpOperator.org_id,
            union_id: pumpId,
            mouza_id: mouzaId
        }
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-information/list-allId', params).then(response => {
        if (response.success) {
            const pumpData = response.data.filter(item => item.status === 0)
            this.pumpList = pumpData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.pump_id }
                } else {
                return { value: obj.id, text: obj.pump_id }
                }
            })
        }
      })
    }
  }
}
</script>
